import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header, Button } from 'components';
import { Layout, Container } from 'layouts';

import  HtmlTableToJson from 'html-table-to-json';

import {  useState,useEffect }from 'react';
//import axios from 'axios';

const jsonTables = new HtmlTableToJson(`
        <table>
            <tr>
                <th>Animal</th>
                <th>Color</th>
                <th>Name</th>
            </tr>
            <tr>
                <td>Unicorn</td>
                <td>Pink</td>
                <td>Billy</td>
            </tr>
            <tr>
                <td>Walrus</td>
                <td>Orange</td>
                <td>Sue</td>
            </tr>
        </table>
    `);
    
const Ratings = function(center) {
  
  const [data, setdata] = useState(0);

  useEffect(() => {



  fetch("http://ecfgrading.org.uk/new/glist.php?Code=8TYN", {
    mode: "no-cors",
    method: "GET"//,
    //headers: {
    //  "Accept": "application/json"
   // }
  })
    .then(response => response.text())
    .then(response => {
      console.log("got",response);
    })



});
  return (
  <Layout>
    <Helmet title={'Tynemouth Chess Club'} />
    <Header title="Tynemouth Chess Club"></Header>
    <Container center={center}>
    {JSON.stringify(jsonTables.results)}
    

<h3> Welcome to Tynemouth Chess Club's website. </h3>
  We meet every Tuesday from 7.00pm at

<p><i><a href="https://www.google.co.uk/maps/place/The+Hunting+Lodge/@55.038342,-1.4754677,17z/data=!3m1!4b1!4m5!3m4!1s0x487e6e77c444c23b:0x2b836b702bdfb260!8m2!3d55.038342!4d-1.473279">Hunting Lodge, Earsdon Road, Whitley Bay, NE25 9SX</a></i></p>
<p>&nbsp;</p>
<p>We have four teams covering the entire range of abilities and have one of the most active club nights around.</p>

    </Container>
  </Layout>
)
  };

export default Ratings;

Ratings.propTypes = {
  center: PropTypes.object,
};
